<template>
    <div class="lasttrades">
        <div class="icon">
            <img src="../../assets/icons/time-is-money1.svg" alt="">
            <span> آخرین معاملات بازار ها </span>
        </div>
        <div class="trades">
            <div v-for="(item,index) in coins" :key="index" class="trade">
                <div class="row">
                    <div class="coins">
                        <div class="changes">
                            <span v-if="item.last24HourChange>0" class="success"> {{$toLocal(item.last24HourChange,2)}} % </span>
                            <span v-if="item.last24HourChange<=0" class="fail"> {{ $toLocal(Math.abs(item.last24HourChange),2) }} % </span>
                            <img src="../../assets/icons/changes.svg" alt="">
                        </div>
                        <img class="coin-img" :src="'/coins/'+$coin2Snake[item.relatedCoin]+'.png'" alt="">
                    </div>
                    <span> {{item.relatedCoin}} </span>
                    <span> {{$coinLabel[item.relatedCoin]}} </span>
                </div>
                <div class="row">
                    <div class="tomany">
                        <img class="coin-img" :src="'/coins/'+'TOMAN'+'.png'" alt="">
                        <span>تومان</span>
                    </div>
                        <span> {{$toLocal(item.lastPrice,2)}} </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'LastTrades',
    data () {
            return {
                coins:[],
                pricetype:'TOMAN',
                TomanCoin: 'market_type=BITCOIN_TOMAN' +
                  '&market_type=BITCOIN_CASH_TOMAN' +
                  '&market_type=ETHEREUM_TOMAN' +
                  '&market_type=LITE_COIN_TOMAN' +
                  '&market_type=BINANCE_COIN_BSC_TOMAN' +
                  
                  '&market_type=STELLAR_TOMAN' +
                  '&market_type=TRON_TOMAN' +
                  '&market_type=CELO_TOMAN' +
                  '&market_type=RIPPLE_TOMAN' +
                  '&market_type=DOGE_COIN_TOMAN' +
                  '&market_type=MATIC_TOMAN' +
                  '&market_type=TETHER_TOMAN',



              TetherCoin: 'market_type=BITCOIN_TETHER' +
                  '&market_type=BITCOIN_CASH_TETHER' +
                  '&market_type=ETHEREUM_TETHER' +
                  '&market_type=LITE_COIN_TETHER' +
                  '&market_type=BINANCE_COIN_BSC_TETHER' +
                  '&market_type=MATIC_TETHER' +

                  '&market_type=STELLAR_TETHER' +
                  '&market_type=TRON_TETHER' +
                  '&market_type=RIPPLE_TETHER' +
                  '&market_type=DOGE_COIN_TETHER' +
                  '&market_type=CELO_TETHER', 
                // coin:[], 
            }
        },
    methods:{
        async setData() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info?' + (this.pricetype == 'TOMAN' ? this.TomanCoin : this.TetherCoin),{
                        params: {
                            weeklyDataIncluded: true,
                        }
                    })
                    this.coins = res.content.slice(0,4)
                    // console.log('week',this.coin);
            },
    },
    mounted(){
        this.setData();
    }
}
</script>

<style lang="scss" scoped>
.tomany{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}
.coin-img{
    max-width: 40px;
    max-height: 40px;
}
.lasttrades{
    z-index: 3;
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.icon{
    width: 20%;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    align-items: center;
}
.trades{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    .trade{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        width: 232px;
        background: white;
        padding: 8px 12px;
        border-radius: 8px;
        .row{
            display: flex;
            flex-direction: row;  
            justify-content: space-between; 
            align-items: center;
            .coins{
                column-gap: 4px;
                display: flex;
                flex-direction: row;
                .changes{
                    display: flex;
                    flex-direction: column;
                    img{
                        width: 30px;
                        height: 20px;
                    }
                }
            }     
        }
    }
}
@media only screen and(max-width:1000px){
    .lasttrades{
        flex-wrap: wrap;
    }
}
</style>